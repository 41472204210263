<script lang="ts">
  import {
    calculateRelativeFrequencies,
    distinctify,
    mean,
    occurencesOf,
  } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  // TODO: Implement some display of values
  export let values: number[] | null;
  $: distinctValues = distinctify(values);
  $: relativeFrequencies = calculateRelativeFrequencies(values, distinctValues);
</script>

<div class="formula table-container">
  <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
    <semantics>
      <mrow>
        <msub>
          <mi>f</mi>
          <mi>j</mi>
        </msub>
        <mo>(</mo>
        <mi>j</mi>
        <mo>=</mo>
        <mn>1</mn>
        <mo>,</mo>
        <mo>…</mo>
        <mo>,</mo>
        <mi>J</mi>
        <mo>)</mo>
        <mo>=</mo>
        {#each distinctValues as distinctValue, i}
          <mfrac>
            <mn>{occurencesOf(distinctValue, values)}</mn>
            <mn>{distinctValues.length}</mn>
          </mfrac>
          {#if i !== distinctValues.length - 1}
            <mo> , </mo>
          {/if}
        {/each}<mo>=</mo>
        {#each relativeFrequencies as relativeFrequency, i}
          <mi>{roundToDigits(relativeFrequency, 3)}</mi>
          {#if i !== relativeFrequencies.length - 1}
            <mo> , </mo>
          {/if}
        {/each}
      </mrow>
    </semantics>
  </math>
</div>
