<!-- Median aka mittlerer Wert -->
<script lang="ts">
  import { median } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";
  $: medianRes = roundToDigits(median(values), 3);

  export let values: number[] | null;
</script>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML">
    <semantics>
      <mrow>
        <msub>
          <mtext>med</mtext>
          <mi>x</mi>
        </msub>
        <mo>=</mo>
        <mn>{values.length !== 0 ? medianRes : 0}</mn>
      </mrow>
    </semantics></math
  >
  <br />
</div>
