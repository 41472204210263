<script lang="ts">
  import { roundToDigits } from "../lib/displayUtils";

  export let topLeftLabel: string = "-";
  export let xLabels: string[];
  export let yLabels: string[];
  export let matrix: unknown[][];
</script>

<div class="table-container">
  <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
    <thead>
      <tr>
        <th><u>{topLeftLabel}</u></th>
        {#each yLabels as yLabel}
          <th>{yLabel}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each matrix as matrixRow, i}
        <tr>
          <th class="has-text-right">{xLabels[i]}</th>
          {#each matrixRow as matrixCell}
            <td>
              {#if typeof matrixCell === "number"}
                {roundToDigits(matrixCell, 3)}
              {:else}
                {@html matrixCell}
              {/if}
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>
