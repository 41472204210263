<script lang="ts">
  import { pQuantilDebug, pQuantil } from "../lib/descriptiveStatistics";
  import { roundToDigits } from "../lib/displayUtils";

  export let values: number[];

  let p: number = 0.25;
  $: isPValid = typeof p === "number" && p >= 0 && p <= 1;
  $: pQuantilResult = isPValid ? pQuantilDebug(values, p) : null;
</script>

<div class="columns">
  <div class="column">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="p-quantil-input">p-Quantil berechnen:</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <input
              id="p-quantil-input"
              class="input"
              class:is-danger={!isPValid}
              maxlength="6"
              minlength="1"
              type="number"
              placeholder="p"
              bind:value={p}
            />
          </div>
          {#if !isPValid}
            <p class="help is-danger">
              p muss eine Zahl zwischen 0 und 1 sein.
            </p>
          {/if}
        </div>
      </div>
    </div>
    {#if isPValid}
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="p-quantil-output0" />
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
                <semantics>
                  <mrow>
                    <mi>np</mi>
                    <mo>=</mo>
                    <mi>{values.length}</mi>
                    <mo>⋅</mo>
                    <mi>{p}</mi>
                    <mo>=</mo>
                    <mi>{pQuantilResult.np}</mi>
                  </mrow>
                </semantics>
              </math>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="p-quantil-output1" />
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
                <semantics>
                  <mrow>
                    <mi>i</mi>
                    <mo>=</mo>
                    {#if Math.floor(pQuantilResult.np) === pQuantilResult.i}
                      <mi>np</mi>
                      <mo>=</mo>
                      <mi>{pQuantilResult.np}</mi>
                    {:else}
                      <mo stretchy="false">⌈</mo><mi>np</mi><mo stretchy="false"
                        >⌉</mo
                      >
                      <mo>=</mo>
                      <mo stretchy="false">⌈</mo><mi>{pQuantilResult.np}</mi><mo
                        stretchy="false">⌉</mo
                      >

                      <mo>=</mo>
                      <mi>{pQuantilResult.i}</mi>
                    {/if}
                  </mrow>
                </semantics>
              </math>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="p-quantil-output2" />
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
                <semantics>
                  <mrow>
                    <msub>
                      <mi>Q</mi>
                      <mi>{p}</mi>
                    </msub>
                    {#if !pQuantilResult.specialCase}
                      <mo>=</mo>
                      {#if Math.floor(pQuantilResult.np) === pQuantilResult.i}
                        <mfrac>
                          <mrow>
                            <msub>
                              <mi>x</mi><mrow
                                ><mo stretchy="false">(</mo><mi>i</mi><mo
                                  stretchy="false">)</mo
                                ></mrow
                              >
                            </msub>
                            <mo>+</mo>
                            <msub>
                              <mi>x</mi><mrow
                                ><mo stretchy="false">(</mo><mi>i</mi><mo>+</mo
                                ><mn>1</mn><mo stretchy="false">)</mo></mrow
                              >
                            </msub>
                          </mrow>
                          <mn>2</mn>
                        </mfrac>
                        <mo>=</mo>
                        <mfrac>
                          <mrow>
                            <msub>
                              <mi>x</mi><mrow
                                ><mo stretchy="false">(</mo><mi
                                  >{pQuantilResult.i}</mi
                                ><mo stretchy="false">)</mo></mrow
                              >
                            </msub>
                            <mo>+</mo>
                            <msub>
                              <mi>x</mi><mrow
                                ><mo stretchy="false">(</mo><mi
                                  >{pQuantilResult.i + 1}</mi
                                ><mo stretchy="false">)</mo></mrow
                              >
                            </msub>
                          </mrow>
                          <mn>2</mn>
                        </mfrac>
                        <mo>=</mo>
                        <mfrac>
                          <mrow>
                            <mi>{values[pQuantilResult.i - 1]}</mi>
                            <mo>+</mo>
                            <mi>{values[pQuantilResult.i]}</mi>
                          </mrow>
                          <mn>2</mn>
                        </mfrac>
                      {:else}
                        <msub>
                          <mi>x</mi><mrow
                            ><mo stretchy="false">(</mo><mi>i</mi><mo
                              stretchy="false">)</mo
                            ></mrow
                          >
                        </msub>
                        <mo>=</mo>
                        <msub>
                          <mi>x</mi><mrow
                            ><mo stretchy="false">(</mo><mi
                              >{pQuantilResult.i}</mi
                            ><mo stretchy="false">)</mo></mrow
                          >
                        </msub>
                      {/if}
                    {/if}
                    <mo>=</mo>
                    <mi>{pQuantilResult.result}</mi>
                  </mrow>
                </semantics>
              </math>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
  <div class="column">
    Interquartilsabstand:
    {pQuantil(values, 0.75)} -
    {pQuantil(values, 0.25)} =

    {roundToDigits(pQuantil(values, 0.75) - pQuantil(values, 0.25), 3)}
  </div>
</div>
