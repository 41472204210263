<script lang="ts">
  import {
    calculateCovariance,
    splitTuples,
  } from "../../lib/bivariateStatistics";
  import type { Tuple } from "../../lib/bivariateStatistics";
  import { roundToDigits } from "../../lib/displayUtils";
  import { calculateVariance, mean } from "../../lib/descriptiveStatistics";
  import Variance from "./Variance.svelte";
  import StandardDeviation from "./StandardDeviation.svelte";
  import Mean from "./Mean.svelte";
  4;

  export let values: Tuple<number>[];
  $: coVariance = calculateCovariance(values);
  $: [xValues, yValues] = splitTuples(values);
  $: xStandardDeviation = Math.sqrt(calculateVariance(xValues));
  $: yStandardDeviation = Math.sqrt(calculateVariance(yValues));
</script>

<div class="formula table-container">
  <Mean label="x" values={xValues} />
</div>
<div class="formula table-container">
  <Variance label="x" values={xValues} />
</div>
<div class="formula table-container">
  <StandardDeviation label="x" values={xValues} />
</div>

<div class="formula table-container">
  <Mean label="y" values={yValues} />
</div>
<div class="formula table-container">
  <Variance label="y" values={yValues} />
</div>
<div class="formula table-container">
  <StandardDeviation label="y" values={yValues} />
</div>

<math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <msub>
    <mi>r</mi>
    <mrow>
      <mi>x</mi>
      <mi>y</mi>
    </mrow>
  </msub>
  <mo>=</mo>
  <mfrac>
    <msub>
      <mi>s</mi>
      <mrow>
        <mi>x</mi>
        <mi>y</mi>
      </mrow>
    </msub>
    <mrow>
      <msub>
        <mi>s</mi>
        <mi>x</mi>
      </msub>
      <mo>·</mo>
      <msub>
        <mi>s</mi>
        <mi>y</mi>
      </msub>
    </mrow>
  </mfrac>
  <mo>=</mo>
  <mfrac>
    <mi>{roundToDigits(coVariance, 3)}</mi>
    <mrow>
      <mi>{roundToDigits(xStandardDeviation, 3)}</mi>
      <mo>·</mo>
      <mi>{roundToDigits(yStandardDeviation, 3)}</mi>
    </mrow>
  </mfrac>
  <mo>=</mo>
  <mfrac>
    <mi>{roundToDigits(coVariance, 3)}</mi>
    <mi>{roundToDigits(xStandardDeviation * yStandardDeviation, 3)}</mi>
  </mfrac>
  <mo>=</mo>
  <mi>
    {roundToDigits(coVariance / (xStandardDeviation * yStandardDeviation), 3)}
  </mi>
</math>
