<!-- Arithmetisches Mittel -->
<script lang="ts">
  import {
    empiricalDistributionFunction,
    letiDispersion,
  } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  // TODO: Implement some display of values
  export let values: number[] | null;

  const allF = empiricalDistributionFunction(values);
  allF.pop();
</script>

<div class="formula table-container">
  <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
    <semantics>
      <mrow>
        <msub>
          <mi>D</mi>
          <mi>L</mi>
        </msub>
        <mo>=</mo>
        <munderover>
          <mo>∑</mo>
          <mrow>
            <mi>j</mi>
            <mo>=</mo>
            <mn>1</mn>
          </mrow>
          <mrow>
            <mi>J</mi>
            <mo>−</mo>
            <mn>1</mn>
          </mrow>
        </munderover>
        <msub>
          <mi>F</mi>
          <mi>N</mi>
        </msub>
        <mrow>
          <mo>[</mo>
          <mrow>
            <mi>x</mi>
            <mo stretchy="false">(</mo>
            <mi>j</mi>
            <mo stretchy="false">)</mo>
          </mrow>
          <mo>]</mo>
        </mrow>
        <mo>⋅</mo>
        <mrow>
          <mo>(</mo>
          <mrow>
            <mn>1</mn>
            <mo>−</mo>
            <msub>
              <mi>F</mi>
              <mi>N</mi>
            </msub>
            <mrow>
              <mo>[</mo>
              <mrow>
                <mi>x</mi>
                <mo stretchy="false">(</mo>
                <mi>j</mi>
                <mo stretchy="false">)</mo>
              </mrow>
              <mo>]</mo>
            </mrow>
          </mrow>
          <mo>)</mo>
        </mrow>

        <mo>=</mo>
        {#each allF as F_N, valueIndex}
          <mn> {roundToDigits(F_N, 3)} </mn>
          <mo>⋅</mo>
          <mn> {roundToDigits(1 - F_N, 3)} </mn>
          {#if valueIndex !== allF.length - 1}
            <mo> + </mo>
          {/if}
        {/each}

        <mo>=</mo>
        <mn> {roundToDigits(letiDispersion(values), 3)} </mn>
      </mrow>
    </semantics>
  </math>
</div>
