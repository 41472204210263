<script lang="ts">
  import { afterUpdate } from "svelte";
  import type { PlotlyWindow } from "../../lib/plotlyTypes";

  interface Trace {
    y: number[];
    type: "box";
  }

  export let values: number[] | null;
  $: traces = [
    {
      y: values,
      type: "box",
      boxpoints: "all",
      jitter: 0.0,
      pointpos: -1.8,
      boxmean: true,
      name: "Box-Whisker-Plot",
    } as Trace,
    {
      y: values,
      type: "box",
      boxpoints: false,
      name: "BoxPlot",
    } as Trace,
  ];

  let plotDivElement: HTMLElement;
  const config = { responsive: true };

  // Plotly needs the plotDivElement to attach to it, so only run this _after_ updates
  afterUpdate(() => {
    ((window as unknown) as PlotlyWindow).Plotly.newPlot(
      plotDivElement,
      traces,
      {
        title: "BoxPlot",
        showlegend: false,
      },
      config
    );
  });
</script>

<div bind:this={plotDivElement} />
