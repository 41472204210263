<script lang="ts">
  import { mean } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  export let values: number[];
  export let label: string = "x";
  $: meanV = mean(values);
</script>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
    <mtable columnalign="left">
      <mtr>
        <mtd>
          <msub>
            <mtext>var</mtext>
            <mi>{label}</mi>
          </msub>
          <mo>=</mo>
          <msubsup>
            <mo> s</mo>
            <mn> {label} </mn>
            <mn> 2 </mn>
          </msubsup>
        </mtd>
        <mtd>
          <mo>=</mo>
          <mfrac>
            <mi>1</mi>
            <mrow>
              <mi>N</mi>
              <mo>-</mo>
              <mn>1</mn>
            </mrow>
          </mfrac>
          <mo>·</mo>
          <munderover>
            <mo>∑</mo>
            <mi>N</mi>
            <mrow>
              <mi>i</mi>
              <mo>=</mo>
              <mn>1</mn>
            </mrow>
          </munderover>
          <mo>(</mo>
          <msub>
            <mi>{label}</mi>
            <mi>i</mi>
          </msub>
          <mo>-</mo>
          <mover>
            <mi>{label}</mi>
            <mo accent="true">_</mo>
          </mover>
          <msup>
            <mo>)</mo>
            <mn>2</mn>
          </msup>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mfrac>
            <mi>1</mi>
            <mrow>
              <mi>{values.length}</mi>
              <mo>-</mo>
              <mn>1</mn>
            </mrow>
          </mfrac>
          <mo>·</mo>
          <munderover>
            <mo>∑</mo>
            <mi>{values.length}</mi>
            <mrow>
              <mi>i</mi>
              <mo>=</mo>
              <mn>1</mn>
            </mrow>
          </munderover>
          <mo>(</mo>
          <msub>
            <mi>{label}</mi>
            <mi>i</mi>
          </msub>
          <mo>-</mo>
          <mi>{roundToDigits(meanV, 3)}</mi>
          <msup>
            <mo>)</mo>
            <mn>2</mn>
          </msup>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (values.length - 1), 3)}</mi>
          <mo>·</mo>
          <mo>(</mo>
          {#each values as x, i}
            <mo>(</mo>
            <mi>{x}</mi>
            <mo>-</mo>
            <mi>{roundToDigits(meanV, 3)}</mi>
            <msup>
              <mo>)</mo>
              <mn>2</mn>
            </msup>

            {#if i !== values.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (values.length - 1), 3)}</mi>
          <mo>·</mo>
          <mo>(</mo>
          {#each values as x, i}
            <mo>(</mo>
            <mi>{roundToDigits(x - meanV, 3)}</mi>
            <msup>
              <mo>)</mo>
              <mn>2</mn>
            </msup>
            {#if i !== values.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (values.length - 1), 3)}</mi>
          <mo>·</mo>
          <mi
            >{roundToDigits(
              values.reduce((sum, x) => sum + (x - meanV) ** 2, 0),
              3
            )}</mi
          >
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>
            {roundToDigits(
              values.reduce((sum, x) => sum + (x - meanV) ** 2, 0) *
                (1 / (values.length - 1)),
              3
            )}
          </mi>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
      </mtr>
    </mtable>
  </math>
</div>
