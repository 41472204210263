<script lang="ts">
  import { calculateVariance } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  export let values: number[];
  export let label: string = "x";
  $: variance = calculateVariance(values);
  //export let mean: number;
</script>

<math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
  <semantics>
    <mrow>
      <msub>
        <mi>s</mi>
        <mi>{label}</mi>
      </msub>
      <mo>=</mo>
      <msqrt>
        <msub>
          <mtext>var</mtext>
          <mi>{label}</mi>
        </msub>
      </msqrt>
      <mo>=</mo>
      <mi>{roundToDigits(Math.sqrt(variance), 3)}</mi>
    </mrow>
  </semantics>
</math>
