<script lang="ts">
  import {
    calculateCovariance,
    splitTuples,
  } from "../../lib/bivariateStatistics";
  import type { Tuple } from "../../lib/bivariateStatistics";
  import { roundToDigits } from "../../lib/displayUtils";
  import {
    calculateRanks,
    calculateVariance,
    mean,
  } from "../../lib/descriptiveStatistics";
  import Covariance from "./Covariance.svelte";
  import Variance from "./Variance.svelte";
  import StandardDeviation from "./StandardDeviation.svelte";
  import Mean from "./Mean.svelte";

  export let values: Tuple<number>[];
  $: [xValues, yValues] = splitTuples(values);
  $: xRanks = calculateRanks(xValues);
  $: yRanks = calculateRanks(yValues);
  $: rankTupels = xRanks.map((xr, i) => [xr, yRanks[i]] as Tuple<number>);
  $: rankCoVariance = calculateCovariance(rankTupels);
  $: xRankStandardDeviation = Math.sqrt(calculateVariance(xRanks));
  $: yRankStandardDeviation = Math.sqrt(calculateVariance(yRanks));
</script>

<div class="formula table-container">
  <table class="table is-bordered is-compact">
    <tr>
      <th> x(i) </th>
      {#each xValues as xVal}
        <td> {xVal} </td>
      {/each}
    </tr>
    <tr>
      <th> R(x) </th>

      {#each xRanks as xRank}
        <td> {xRank} </td>
      {/each}
    </tr>
  </table>
</div>

<div class="formula table-container">
  <table class="table is-bordered is-compact">
    <tr>
      <th> y(i) </th>
      {#each yValues as yVal}
        <td> {yVal} </td>
      {/each}
    </tr>
    <tr>
      <th> R(y) </th>

      {#each yRanks as yRank}
        <td> {yRank} </td>
      {/each}
    </tr>
  </table>
</div>

<div class="formula table-container">
  <Covariance tuples={rankTupels} xLabel="R(x)" yLabel="R(y)" />
</div>
<br />
<div class="formula table-container">
  <Mean label="R(x)" values={xRanks} />
</div>
<div class="formula table-container">
  <Variance label="R(x)" values={xRanks} />
</div>
<div class="formula table-container">
  <StandardDeviation label="R(x)" values={xRanks} />
</div>
<br />
<div class="formula table-container">
  <Mean label="R(y)" values={yRanks} />
</div>
<div class="formula table-container">
  <Variance label="R(y)" values={yRanks} />
</div>
<div class="formula table-container">
  <StandardDeviation label="R(y)" values={yRanks} />
</div>

<math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <msubsup>
    <mo> r</mo>
    <mn> xy </mn>
    <mn> Sp </mn>
  </msubsup>

  <mo>=</mo>
  <msub>
    <mi>r</mi>
    <mrow>
      <mi>R</mi>
      <mo>(</mo>
      <mi>x</mi>
      <mo>)</mo>
      <mi>R</mi>
      <mo>(</mo>
      <mi>y</mi>
      <mo>)</mo>
    </mrow>
  </msub>
  <mo>=</mo>
  <mfrac>
    <msub>
      <mi>s</mi>
      <mrow>
        <mi>R</mi>
        <mo>(</mo>
        <mi>x</mi>
        <mo>)</mo>
        <mi>R</mi>
        <mo>(</mo>
        <mi>y</mi>
        <mo>)</mo>
      </mrow>
    </msub>
    <mrow>
      <msub>
        <mi>s</mi>
        <mrow>
          <mi>R</mi>
          <mo>(</mo>
          <mi>x</mi>
          <mo>)</mo>
        </mrow>
      </msub>
      <mo>·</mo>
      <msub>
        <mi>s</mi>
        <mrow>
          <mi>R</mi>
          <mo>(</mo>
          <mi>y</mi>
          <mo>)</mo>
        </mrow>
      </msub>
    </mrow>
  </mfrac>
  <mo>=</mo>
  <mfrac>
    <mi>
      {roundToDigits(rankCoVariance, 3)}
    </mi>
    <mrow>
      <mi>
        {roundToDigits(xRankStandardDeviation, 3)}
      </mi>
      <mo>·</mo>
      <mi>
        {roundToDigits(yRankStandardDeviation, 3)}
      </mi>
    </mrow>
  </mfrac>
  <mo>=</mo>
  <mfrac>
    <mi>
      {roundToDigits(rankCoVariance, 3)}
    </mi>
    <mi>
      {roundToDigits(xRankStandardDeviation * yRankStandardDeviation, 3)}
    </mi>
  </mfrac>
  <mo>=</mo>
  <mi>
    {roundToDigits(
      rankCoVariance / (xRankStandardDeviation * yRankStandardDeviation),
      3
    )}
  </mi>
</math>
