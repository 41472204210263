<!-- Modus aka Modalwert -->
<script lang="ts">
  import { mode } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  export let values: number[] | null;
</script>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML">
    <semantics>
      <mrow>
        <msub>
          <mtext>mod</mtext>
          <mi>x</mi>
        </msub>
        <mo>=</mo>
        <mn>{values.length !== 0 ? roundToDigits(mode(values), 3) : 0}</mn>
      </mrow>
    </semantics></math
  >
</div>
