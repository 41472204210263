<script lang="ts">
  import { median } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";
  $: calculatedMedian = median(values);
  $: medianRes = roundToDigits(calculatedMedian, 3);

  export let values: number[] = [];
  $: N = values.length;
  $: values2 = values.map((v) => v - calculatedMedian);
  $: values3 = values2.map((v) => Math.abs(v));
  $: values3Sum = values3.reduce((sum, v) => sum + v, 0);
</script>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
    <mtable columnalign="left">
      <mtr>
        <mtd>
          <msub>
            <mtext>md </mtext>
            <mi>x </mi>
          </msub>
        </mtd>
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mn>1 </mn>
            <mi>n </mi>
          </mfrac>
          <mo>· </mo>
          <munderover>
            <mo>∑ </mo>
            <mrow>
              <mi>i </mi>
              <mo>= </mo>
              <mn>1 </mn>
            </mrow>
            <mi>n </mi>
          </munderover>
          <mrow>
            <mo stretchy="true" form="prefix">| </mo>
            <mrow>
              <msub>
                <mi>x </mi>
                <mi>i </mi>
              </msub>
              <mo>- </mo>
              <msub>
                <mtext>med </mtext>
                <mi>x </mi>
              </msub>
            </mrow>
            <mo stretchy="true" form="postfix">| </mo>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mn>1 </mn>
            <mi>{N} </mi>
          </mfrac>
          <mo>· </mo>
          <munderover>
            <mo>∑ </mo>
            <mrow>
              <mi>i </mi>
              <mo>= </mo>
              <mn>1 </mn>
            </mrow>
            <mi>{N} </mi>
          </munderover>
          <mrow>
            <mo stretchy="true" form="prefix">| </mo>
            <mrow>
              <msub>
                <mi>x </mi>
                <mi>i </mi>
              </msub>
              <mo>- </mo>
              <msub>
                <mtext>med </mtext>
                <mi>x </mi>
              </msub>
            </mrow>
            <mo stretchy="true" form="postfix">| </mo>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mn>1 </mn>
            <mi>{N} </mi>
          </mfrac>
          <mo>· </mo>

          <mo stretchy="true" form="prefix">( </mo>
          {#each values as value, i}
            <mrow>
              <mo stretchy="true" form="prefix">|</mo>
              <mrow>
                <mi>{roundToDigits(value, 3)}</mi>
                <mo>-</mo>
                <mi>{medianRes}</mi>
              </mrow>
              <mo stretchy="true" form="postfix">|</mo>
            </mrow>
            {#if i !== values.length - 1}
              <mo>+</mo>
            {/if}
          {/each}

          <mo stretchy="true" form="prefix">) </mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mn>1 </mn>
            <mi>{N} </mi>
          </mfrac>
          <mo>· </mo>
          <mo stretchy="true" form="prefix">( </mo>
          {#each values2 as value, i}
            <mrow>
              <mo stretchy="true" form="prefix">|</mo>
              <mrow>
                <mi>{roundToDigits(value, 3)}</mi>
              </mrow>
              <mo stretchy="true" form="postfix">|</mo>
            </mrow>
            {#if i !== values.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo stretchy="true" form="prefix">) </mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mn>1 </mn>
            <mi>{N} </mi>
          </mfrac>
          <mo>· </mo>
          <mo stretchy="true" form="prefix">( </mo>
          {#each values3 as value, i}
            <mi>{roundToDigits(value, 3)}</mi>
            {#if i !== values.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo stretchy="true" form="prefix">) </mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mn>1 </mn>
            <mi>{N} </mi>
          </mfrac>
          <mo>· </mo>
          <mi>{roundToDigits(values3Sum, 3)}</mi>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mfrac>
            <mi>{roundToDigits(values3Sum, 3)} </mi>
            <mi>{N} </mi>
          </mfrac>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mi>{roundToDigits(values3Sum / N, 3)} </mi>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
      </mtr>
    </mtable>
  </math>
</div>
