<script lang="ts">
  import Mean from "./formulas/Mean.svelte";
  import Median from "./formulas/Median.svelte";
  import Mode from "./formulas/Mode.svelte";
  import RelativeFrequencies from "./formulas/RelativeFrequencies.svelte";
  import BoxPlot from "./graphs/BoxPlot.svelte";
  import EmpiricalDistribution from "./graphs/EmpiricalDistribution.svelte";
  import { distinctify } from "../lib/descriptiveStatistics";
  import SimpsonsD from "./formulas/SimpsonsD.svelte";
  import Quantil from "./formulas/Quantil.svelte";
  import QuantilCalculator from "./QuantilCalculator.svelte";
  import SimpsonsDNormed from "./formulas/SimpsonsDNormed.svelte";
  import LetisD from "./formulas/LetisD.svelte";
  import LetisDNormed from "./formulas/LetisDNormed.svelte";
  import Variance from "./formulas/Variance.svelte";
  import StandardDeviation from "./formulas/StandardDeviation.svelte";
  import Mad from "./formulas/MAD.svelte";
  import Md from "./formulas/MD.svelte";
  import VariationCoefficient from "./formulas/VariationCoefficient.svelte";

  let valueInputStr: string =
    "8.0, 4.9, 6.6, 3.2, 3.9, 4.5, 6.1, 3.7, 4.2, 8.5, 3.6, 3.7, 12.0";
  $: valueSet = valueInputStr
    ?.split(",")
    .map((v) => v.trim())
    .filter((v) => v.length !== 0)
    .map((v) => parseFloat(v))
    .filter((v) => !isNaN(v));
  $: sortedValues = valueSet.sort((a, b) => a - b);
  $: distinctValues = distinctify(sortedValues);

  let activeTabIndex: number = 0;
</script>

<form class="box">
  <div class="field">
    <label class="label" for="x-values-input"
      >Urliste der x-Werte (N={valueSet.length})</label
    >
    <p class="help">
      [Wertemengen anlegen] nimmt die Eingabe (z.B. "3,4") und legt pro Position
      so viele Elemente an (Im Bsp. also "1,1,1, 2,2,2,2")
    </p>
  </div>
  <div class="field has-addons">
    <div class="control is-expanded">
      <input
        id="x-values-input"
        class="input"
        type="text"
        bind:value={valueInputStr}
        placeholder="1.6, 12.3, 15.3, 33.4"
      />
    </div>
    <div class="control">
      <button
        class="button is-primary"
        on:click|preventDefault={() => {
          let newInputArray = [];
          let counter = 1;
          for (const value of valueSet) {
            const values = new Array(Math.floor(value));
            values.fill(counter);
            newInputArray.push(...values);
            counter++;
          }
          if (newInputArray.length > 10000) {
            alert(
              `Du würdest hiermit ${newInputArray.length} x-Werte anlegen. Das ist wahrscheinlich zu viel für deinen Browser.`
            );
          } else {
            valueInputStr = newInputArray.join(", ");
          }
        }}
      >
        Wertemengen anlegen
      </button>
    </div>
  </div>
  <div class="field">
    <label class="label" for="x-values-sorted">Sortierte x-Werte</label>
    <div class="control">
      <input
        disabled
        id="x-values-sorted"
        class="input"
        type="text"
        value={sortedValues.join("  ,  ")}
      />
    </div>
  </div>
  <div class="field">
    <label class="label" for="x-values-distinct"
      >Sortierter Wertebereich (J={distinctValues.length})</label
    >
    <div class="control">
      <input
        disabled
        id="x-values-distinct"
        class="input"
        type="text"
        value={distinctValues.join("  ,  ")}
      />
    </div>
  </div>
  <div class="tabs is-boxed is-centered">
    <ul>
      <li
        class:is-active={activeTabIndex === 0}
        on:click={() => {
          activeTabIndex = 0;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span
            ><math display="inline" xmlns="http://www.w3.org/1998/Math/MathML"
              ><semantics><msub><mi>med</mi><mi>x</mi></msub></semantics></math
            ></span
          >
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 1}
        on:click={() => {
          activeTabIndex = 1;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span
            ><math display="inline" xmlns="http://www.w3.org/1998/Math/MathML"
              ><semantics><msub><mi>mod</mi><mi>x</mi></msub></semantics></math
            ></span
          >
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 2}
        on:click={() => {
          activeTabIndex = 2;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span
            ><math display="inline" xmlns="http://www.w3.org/1998/Math/MathML"
              ><semantics><mover><mi>x</mi><mo>¯</mo></mover></semantics></math
            ></span
          >
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 3}
        on:click={() => {
          activeTabIndex = 3;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span
            ><math display="inline" xmlns="http://www.w3.org/1998/Math/MathML"
              ><semantics><msub><mi>f</mi><mi>j</mi></msub></semantics></math
            ></span
          >
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 4}
        on:click={() => {
          activeTabIndex = 4;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span>
            <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
              <semantics>
                <mrow>
                  <mi>D</mi><mo>,</mo>
                  <msub><mi>D</mi><mi>Z</mi></msub>
                </mrow>
              </semantics>
            </math>
          </span>
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 5}
        on:click={() => {
          activeTabIndex = 5;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span>
            <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
              <semantics>
                <mrow>
                  <msub><mi>D</mi><mi>L</mi></msub><mo>,</mo>
                  <msub><mi>D</mi><mi>LZ</mi></msub>
                </mrow>
              </semantics>
            </math>
          </span>
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 6}
        on:click={() => {
          activeTabIndex = 6;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span
            ><math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
              <semantics>
                <mrow>
                  <msub>
                    <mi>Q</mi>
                    <mi>p</mi>
                  </msub>
                </mrow>
              </semantics>
            </math></span
          >
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 7}
        on:click={() => {
          activeTabIndex = 7;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <span
            ><math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
              <semantics>
                <mrow>
                  <msub>
                    <mi>s</mi>
                    <mi>x</mi>
                  </msub>
                </mrow>
              </semantics>
            </math></span
          >
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 8}
        on:click={() => {
          activeTabIndex = 8;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
            <semantics><msub><mi>v</mi><mi>x</mi></msub></semantics>
          </math>
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 9}
        on:click={() => {
          activeTabIndex = 9;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
            <semantics><msub><mi>md</mi><mi>x</mi></msub></semantics>
          </math>
        </a>
      </li>
      <li
        class:is-active={activeTabIndex === 10}
        on:click={() => {
          activeTabIndex = 10;
        }}
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <a>
          <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
            <semantics><msub><mi>mad</mi><mi>x</mi></msub></semantics>
          </math>
        </a>
      </li>
    </ul>
  </div>
  <div class="content">
    {#if activeTabIndex === 0}
      <article class="content">
        <Median values={sortedValues} />
      </article>
      <article class="message is-success">
        <div class="message-body">
          Der <strong>Median</strong> (auch Zentralwert) ist der Wert, der in der
          Mitte der sortierten Urliste liegt. Der Median ist ein Lageparameter. Er
          ist im Gegensatz zum Mittelwert resistenter gegen Außreißer.
        </div>
      </article>
    {:else if activeTabIndex === 1}
      <article class="content">
        <Mode values={sortedValues} />
      </article>
      <article class="message is-success">
        <div class="message-body">
          Der <strong>Modus</strong> (auch Modalwert, engl: Mode) ist der Wert, der
          am häufigste in der Urliste vorkommt. Der Modus ist ein Lageparameter.
        </div>
      </article>
    {:else if activeTabIndex === 2}
      <article class="content">
        <Mean values={sortedValues} />
      </article>

      <article class="message is-success">
        <div class="message-body">
          Der <strong>Arithmetische Mittelwert</strong> (auch Durchschnitt,
          engl: Mean)
          <math xmlns="http://www.w3.org/1998/Math/MathML"
            ><semantics><mover><mi>x</mi><mo>¯</mo></mover></semantics></math
          >
          ist ein Lageparameter.
        </div>
      </article>
    {:else if activeTabIndex === 3}
      <article class="content">
        <RelativeFrequencies values={sortedValues} />
      </article>

      <article class="message is-success">
        <div class="message-body">
          Die <strong>Relative Häufigkeit</strong>
          <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML"
            ><semantics><msub><mi>f</mi><mi>j</mi></msub></semantics></math
          >
          ist ein Wert zwischen 0 und 1 und sagt aus, wie (relativ) häufig der j-te
          Wert in der Urliste vorkommt.
        </div>
      </article>
    {:else if activeTabIndex === 4}
      <article class="content">
        <SimpsonsD values={sortedValues} />
        <SimpsonsDNormed values={sortedValues} />
      </article>

      <article class="message is-success">
        <div class="message-body">
          <strong>Simpson's D</strong>: Dispersionsindex nach Simpson.
          Verringert sich mit zunehmender Streuung.
        </div>
      </article>
    {:else if activeTabIndex === 5}
      <article class="content">
        <LetisD values={sortedValues} />
        <LetisDNormed values={sortedValues} />
      </article>

      <article class="message is-success">
        <div class="message-body">
          <strong>Leti's D</strong>: Dispersionsindex nach Leti.
        </div>
      </article>
    {:else if activeTabIndex === 6}
      <article class="content">
        <QuantilCalculator values={sortedValues} />
      </article>

      <article class="message is-success">
        <div class="message-body">
          Das <strong>p-Quantil</strong> ist ein weiteres Lagemaß. Es
          verallgemeintert den Median (das 0.5-Quantil) auf beliebige Werte
          zwischen 0 und 1. Vereinfacht gesagt wird der p*n-te Wert genommen,
          wobei n die Anzahl der sortierten Elemente ist. Das 0.25-Quantil (
          <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
            <semantics><msub><mi>q</mi><mi>4</mi></msub></semantics>
          </math>
          ) und das 0.75-Quantil (
          <math display="inline" xmlns="http://www.w3.org/1998/Math/MathML">
            <semantics><msup><mi>q</mi><mi>4</mi></msup></semantics>
          </math>
          ) werden auch oberes und unteres <b>Quartil</b> genannt.
          <div class="mt-4">
            <Quantil />
          </div>
        </div>
      </article>
    {:else if activeTabIndex === 7}
      <article class="content">
        <Variance values={sortedValues} />
        <StandardDeviation values={sortedValues} />
      </article>
      <article class="message is-success">
        <div class="message-body">
          Die <b>Standardabweichung</b> bezeichnet die Stärke der Abweichung aller
          Werte vom arithmetischen Mittel.
        </div>
      </article>
    {:else if activeTabIndex === 8}
      <VariationCoefficient values={sortedValues} />
      <article class="message is-success">
        <div class="message-body">
          Der <b>Variationskoeffizient</b> ist die relative Standardabweichung, ist
          also ein Maß für die Stärke der Streuung der Werte um den Median herum.
        </div>
      </article>
    {:else if activeTabIndex === 9}
      <Md values={sortedValues} />
      <article class="message is-success">
        <div class="message-body">
          Die <b>Mittlere absolute Medianabweichung</b>, kurz MD, beziffert die
          durchschnittliche Abweichung vom Medianwert, ist also ein Maß für die
          Stärke der Streuung der Werte um den Median herum.
        </div>
      </article>
    {:else if activeTabIndex === 10}
      <Mad values={sortedValues} />
      <article class="message is-success">
        <div class="message-body">
          Die <b>Mediane absolute Medianabweichung</b>, kurz MAD, beziffert die
          mediane Abweichung vom Medianwert, ist also ein Maß für die Stärke der
          Streuung der Werte um den Median herum.
        </div>
      </article>
    {/if}
  </div>
</form>
<div class="columns is-desktop">
  <div class="column">
    <div class="box">
      <BoxPlot values={sortedValues} />
    </div>
  </div>
  <div class="column">
    <div class="box">
      <EmpiricalDistribution values={sortedValues} />
    </div>
  </div>
</div>
