<!-- Arithmetisches Mittel -->
<script lang="ts">
  import { mean } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  export let values: number[] = [];
  export let label: string = "x";
</script>

<div class="formula table-container">
  <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
    <semantics>
      <mrow>
        <mover>
          <mi>{label}</mi>
          <mo>¯</mo>
        </mover>
        <mo>=</mo>
        <mfrac>
          <mn>1</mn>
          <mi>N</mi>
        </mfrac>
        <munderover>
          <mo>∑</mo>
          <mrow>
            <mi>i</mi>
            <mo>=</mo>
            <mn>1</mn>
          </mrow>
          <mi>N</mi>
        </munderover>
        <msub>
          <mi>{label}</mi>
          <mi>i</mi>
        </msub>
        <mo>=</mo>
        {#if values.length !== 0}
          <mfrac>
            <mn>1</mn>
            <mi>{values.length}</mi>
          </mfrac><mo>⋅</mo>
          <mo>(</mo>
          {#each values as value, valueIndex}
            <mn> {value} </mn>
            {#if valueIndex !== values.length - 1}
              <mo> + </mo>
            {/if}
          {/each}
          <mo> ) </mo>
          <mo> = </mo>
          <mn> {roundToDigits(mean(values), 3)} </mn>
        {:else}
          <mi>0</mi>
        {/if}
      </mrow>
    </semantics></math
  >
</div>
