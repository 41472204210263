<script lang="ts">
  import { calculateVariance, mean } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";
  export let values: number[];
  $: meanVal = mean(values);
  $: variance = calculateVariance(values);
  $: standardDeviationVal = Math.sqrt(variance);
</script>

<math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <msub>
    <mi>v </mi>
    <mi>x </mi>
  </msub>
  <mo>=</mo>
  <mfrac>
    <msub>
      <mi>s </mi>
      <mi>x </mi>
    </msub>
    <mover>
      <mi>x </mi>
      <mo accent="true">_ </mo>
    </mover>
  </mfrac>
  <mo>=</mo>
  <mfrac>
    <mi>{roundToDigits(standardDeviationVal, 3)}</mi>
    <mi>{roundToDigits(meanVal, 3)}</mi>
  </mfrac>
  <mo>=</mo>
  <mi>{roundToDigits(standardDeviationVal / meanVal, 3)}</mi>
</math>
