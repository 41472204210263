<script lang="ts">
  import { splitTuples } from "../../lib/bivariateStatistics";
  import type { Tuple } from "../../lib/bivariateStatistics";
  import { mean } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  export let tuples: Tuple<number>[];
  export let xLabel: string = "x";
  export let yLabel: string = "y";

  $: [xVals, yVals] = splitTuples(tuples);
  $: xAverage = mean(xVals);
  $: yAverage = mean(yVals);
  $: sum = tuples.reduce(
    (sum, [x, y]) => sum + (x - xAverage) * (y - yAverage),
    0
  );
  $: N = tuples.length;
  $: covariance = (1 / (N - 1)) * sum;
</script>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
    <mtable columnalign="left">
      <mtr>
        <mtd>
          <msub>
            <mi>s</mi>
            <mrow>
              <mi>{xLabel}</mi>
              <mi>{yLabel}</mi>
            </mrow>
          </msub>
        </mtd>
        <mtd>
          <mo>=</mo>
          <mfrac>
            <mn>1</mn>
            <mrow>
              <mi>N</mi>
              <mo>-</mo>
              <mn>1</mn>
            </mrow>
          </mfrac>
          <mo>·</mo>
          <munderover>
            <mo>∑</mo>
            <mrow>
              <mi>n</mi>
              <mo>=</mo>
              <mn>1</mn>
            </mrow>
            <mi>N</mi>
          </munderover>
          <mo>(</mo>
          <msub>
            <mi>{xLabel}</mi>
            <mi>n</mi>
          </msub>
          <mo>-</mo>
          <mover>
            <mi>{xLabel}</mi>
            <mo accent="true">_</mo>
          </mover>
          <mo>)</mo>
          <mo>(</mo>
          <msub>
            <mi>{yLabel}</mi>
            <mi>n</mi>
          </msub>
          <mo>-</mo>
          <mover>
            <mi>{yLabel}</mi>
            <mo accent="true">_</mo>
          </mover>
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mfrac>
            <mn>1</mn>
            <mrow>
              <mi>{N}</mi>
              <mo>-</mo>
              <mn>1</mn>
            </mrow>
          </mfrac>
          <mo>·</mo>
          <munderover>
            <mo>∑</mo>
            <mrow>
              <mi>n</mi>
              <mo>=</mo>
              <mn>1</mn>
            </mrow>
            <mi>{N}</mi>
          </munderover>
          <mo>(</mo>
          <msub>
            <mi>{xLabel}</mi>
            <mi>n</mi>
          </msub>
          <mo>-</mo>
          <mover>
            <mi>{xLabel}</mi>
            <mo accent="true">_</mo>
          </mover>
          <mo>)</mo>
          <mo>(</mo>
          <msub>
            <mi>{yLabel}</mi>
            <mi>n</mi>
          </msub>
          <mo>-</mo>
          <mover>
            <mi>{yLabel}</mi>
            <mo accent="true">_</mo>
          </mover>
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (N - 1), 3)}</mi>
          <mo>·</mo>
          <mo>(</mo>
          {#each tuples as [x, y], i}
            <mo>(</mo>

            <mi>{x}</mi>
            <mo>-</mo>
            <mi>{roundToDigits(xAverage, 3)}</mi>
            <mo>)</mo>
            <mo>(</mo>
            <mi>{y}</mi>
            <mo>-</mo>
            <mi>{roundToDigits(yAverage, 3)}</mi>
            <mo>)</mo>
            {#if i !== tuples.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (N - 1), 3)}</mi>
          <mo>·</mo>
          <mo>(</mo>
          {#each tuples as [x, y], i}
            <mo>(</mo>
            <mi>{roundToDigits(x - xAverage, 3)}</mi>
            <mo>)</mo>
            <mo>(</mo>
            <mi>{roundToDigits(y - yAverage, 3)}</mi>
            <mo>)</mo>
            {#if i !== tuples.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (N - 1), 3)}</mi>
          <mo>·</mo>
          <mo>(</mo>
          {#each tuples as [x, y], i}
            <mo>(</mo>
            <mi>{roundToDigits((x - xAverage) * (y - yAverage), 3)}</mi>
            <mo>)</mo>
            {#if i !== tuples.length - 1}
              <mo>+</mo>
            {/if}
          {/each}
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(1 / (N - 1), 3)}</mi>
          <mo>·</mo>
          <mo>(</mo>
          <mi>{roundToDigits(sum, 3)}</mi>
          <mo>)</mo>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>=</mo>
          <mi>{roundToDigits(covariance, 3)}</mi>
        </mtd>
      </mtr>
    </mtable>
  </math>
</div>
