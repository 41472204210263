<script lang="ts">
  import { roundToDigits } from "../../lib/displayUtils";

  export let N: number;
  export let J: number;
  export let K: number;
  export let chiSquared: number;
</script>

<math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <mtable columnalign="left">
    <mtr>
      <mtd>
        <mi>C</mi>
      </mtd>
      <mtd>
        <mo>=</mo>
        <msqrt>
          <mrow>
            <mfrac>
              <msup>
                <mi>χ</mi>
                <mn>2</mn>
              </msup>
              <mrow>
                <msup>
                  <mi>χ</mi>
                  <mn>2</mn>
                </msup>
                <mo>+</mo>
                <mi>N</mi>
              </mrow>
            </mfrac>
            <mo>·</mo>
            <mfrac>
              <mrow>
                <mtext>min</mtext>
                <mo>{"{"}</mo>
                <mi>J</mi>
                <mo>,</mo>
                <mi>K</mi>
                <mo>}</mo>
              </mrow>
              <mrow>
                <mtext>min</mtext>
                <mo>{"{"}</mo>
                <mi>J</mi>
                <mo>,</mo>
                <mi>K</mi>
                <mo>}</mo>
                <mo>-</mo>
                <mn>1</mn>
              </mrow>
            </mfrac>
          </mrow>
        </msqrt>
      </mtd>
    </mtr>
    <mtr>
      <mtd />
      <mtd>
        <mo>=</mo>
        <msqrt>
          <mrow>
            <mfrac>
              <mi>{roundToDigits(chiSquared, 3)}</mi>
              <mrow>
                <mi>{roundToDigits(chiSquared, 3)}</mi>
                <mo>+</mo>
                <mi>{N}</mi>
              </mrow>
            </mfrac>
            <mo>·</mo>
            <mfrac>
              <mrow>
                <mtext>min</mtext>
                <mo>{"{"}</mo>
                <mi>{J}</mi>
                <mo>,</mo>
                <mi>{K}</mi>
                <mo>}</mo>
              </mrow>
              <mrow>
                <mtext>min</mtext>
                <mo>{"{"}</mo>
                <mi>{J}</mi>
                <mo>,</mo>
                <mi>{K}</mi>
                <mo>}</mo>
                <mo>-</mo>
                <mn>1</mn>
              </mrow>
            </mfrac>
          </mrow>
        </msqrt>
      </mtd>
    </mtr>
    <mtr>
      <mtd />
      <mtd>
        <mo>=</mo>
        <msqrt>
          <mrow>
            <mfrac>
              <mrow>
                <mi>{roundToDigits(chiSquared, 3)}</mi>
              </mrow>
              <mrow>
                <mi>{roundToDigits(chiSquared + N, 3)}</mi>
              </mrow>
            </mfrac>
            <mo>·</mo>
            <mfrac>
              <mrow>
                <mi>{Math.min(J, K)}</mi>
              </mrow>
              <mrow>
                <mi>{Math.min(J, K)}</mi>
                <mo>-</mo>
                <mn>1</mn>
              </mrow>
            </mfrac>
          </mrow>
        </msqrt>
      </mtd>
    </mtr>
    <mtr>
      <mtd />
      <mtd>
        <mo>=</mo>
        <msqrt>
          <mrow>
            <mfrac>
              <mrow>
                <mi>{roundToDigits(chiSquared, 3)}</mi>
              </mrow>
              <mrow>
                <mi>{roundToDigits(chiSquared + N, 3)}</mi>
              </mrow>
            </mfrac>
            <mo>·</mo>
            <mfrac>
              <mrow>
                <mi>{Math.min(J, K)}</mi>
              </mrow>
              <mrow>
                <mi>{Math.min(J, K) - 1}</mi>
              </mrow>
            </mfrac>
          </mrow>
        </msqrt>
      </mtd>
    </mtr>
    <mtr>
      <mtd />
      <mtd>
        <mo>=</mo>
        <msqrt>
          <mrow>
            <mfrac>
              <mrow>
                <mi>{roundToDigits(chiSquared * Math.min(J, K), 3)}</mi>
              </mrow>
              <mrow>
                <mi
                  >{roundToDigits(
                    (chiSquared + N) * (Math.min(J, K) - 1),
                    3
                  )}</mi
                >
              </mrow>
            </mfrac>
          </mrow>
        </msqrt>
      </mtd>
    </mtr>
    <mtr>
      <mtd />
      <mtd>
        <mo>=</mo>
        <mo>
          {roundToDigits(
            Math.sqrt(
              (chiSquared * Math.min(J, K)) /
                ((chiSquared + N) * (Math.min(J, K) - 1))
            ),
            5
          )}
        </mo>
      </mtd>
    </mtr>
    <mtr>
      <mtd />
    </mtr>
  </mtable>
</math>
