<script lang="ts">
  import { afterUpdate } from "svelte";
  import {
    calculateRelativeFrequencies,
    distinctify,
    empiricalDistributionFunction,
  } from "../../lib/descriptiveStatistics";
  import type { PlotlyWindow } from "../../lib/plotlyTypes";

  interface Trace {
    y: number[];
    x: number[];
    mode: "lines+markers";
    name: string;
    line: { shape: "hv" };
    type: "scatter";
  }

  interface Trace2 {
    x: number[];
    y: number[];
    mode: "markers";
    type: "scatter";
  }

  export let values: number[] | null;
  $: distinctValues = distinctify(values);
  $: empiricalDistributionValues = empiricalDistributionFunction(
    values,
    distinctValues
  );
  $: relativeFrequencies = calculateRelativeFrequencies(values, distinctValues);
  $: traces = [
    {
      y: empiricalDistributionValues,
      x: distinctValues,
      mode: "lines+markers",
      name: "F_N",
      line: { shape: "hv" },
      type: "scatter",
    } as Trace,
    {
      y: relativeFrequencies,
      x: distinctValues,
      type: "scatter",
      mode: "markers",
      name: "f_j",
    } as Trace2,
  ];

  let plotDivElement: HTMLElement;
  const config = { responsive: true };

  // Plotly needs the plotDivElement to attach to it, so only run this _after_ updates
  afterUpdate(() => {
    ((window as unknown) as PlotlyWindow).Plotly.newPlot(
      plotDivElement,
      traces,
      {
        title: "Empirische Verteilung",
        xaxis: {
          title: "x (distinct & sorted)",
        },
      },
      config
    );
  });
</script>

<div bind:this={plotDivElement} />
