<!-- Arithmetisches Mittel -->
<script lang="ts">
  import {
    simpsonsD,
    calculateRelativeFrequencies,
    simpsonsDNormed,
  } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  // TODO: Implement some display of values
  export let values: number[] | null;
</script>

<div class="formula table-container">
  <math
    display="block"
    xmlns="http://www.w3.org/1998/Math/MathML"
    maxwidth="300px"
    linebreakmultchar="×"
    linebreakstyle="before"
  >
    <semantics>
      <mrow>
        <mi>D</mi>
        <mo id="equals">=</mo>
        <mn>1</mn>
        <mo>−</mo>
        <msubsup>
          <mo>∑</mo>
          <mrow>
            <mi>j</mi>
            <mo>=</mo>
            <mn>1</mn>
          </mrow>
          <mi>J</mi>
        </msubsup>
        <msubsup>
          <mi>f</mi>
          <mi>j</mi>
          <mn>2</mn>
        </msubsup>
        {#if values.length !== 0}
          <mo linebreak="newline" indentalign="id" indenttarget="equals">=</mo>
          <mn>1</mn>
          <mo>−</mo>
          <mo stretchy="false" form="prefix">(</mo>
          {#each calculateRelativeFrequencies(values) as value, valueIndex}
            <msup>
              <mn> {roundToDigits(value, 3)} </mn>
              <mn>2</mn>
            </msup>
            {#if valueIndex !== values.length - 1}
              <mo> + </mo>
            {/if}
          {/each}
          <mo stretchy="false" form="postfix">)</mo>
          <mo>=</mo>

          <mn>1</mn>
          <mo>−</mo>
          <mo stretchy="false" form="prefix">(</mo>
          {#each calculateRelativeFrequencies(values) as value, valueIndex}
            <mn> {roundToDigits(value ** 2, 4)} </mn>
            {#if valueIndex !== values.length - 1}
              <mo> + </mo>
            {/if}
          {/each}
          <mo stretchy="false" form="postfix">)</mo>

          <mo>=</mo>
          <mn>1</mn>
          <mo>−</mo>
          <mn>
            {roundToDigits(
              calculateRelativeFrequencies(values).reduce(
                (sum, curr) => sum + curr ** 2,
                0
              ),
              3
            )}
          </mn>
          <mo>=</mo>
          <mn>{roundToDigits(simpsonsD(values), 3)}</mn>
        {:else}
          <mo>=</mo>
          <mi>0</mi>
        {/if}
      </mrow>
    </semantics>
  </math>
</div>
