<script lang="ts">
  import {
    getUniqueTupleParts,
    getAbsoluteFrequencyMatrix,
    getRelativeFrequencyMatrix,
    calculateContingencyTable,
    calculateConditionalXFrequencies,
    calculateConditionalYFrequencies,
    calculateChiSquared,
    calculateCorrectedContingencyCoefficient,
    calculateVjk,
    calculateCovariance,
    splitTuples,
  } from "../lib/bivariateStatistics";

  import type { Tuple } from "../lib/bivariateStatistics";
  import Matrix from "./Matrix.svelte";
  import TableInputModal from "./TableInputModal.svelte";
  import C from "./formulas/C.svelte";
  import ChiSquared from "./formulas/ChiSquared.svelte";
  import { roundToDigits } from "../lib/displayUtils";
  import Covariance from "./formulas/Covariance.svelte";
  import LinearRegression from "./LinearRegression.svelte";
  import BravisPersonCorrelation from "./formulas/BravisPersonCorrelation.svelte";
  import SpearmanRankCorrelation from "./formulas/SpearmanRankCorrelation.svelte";
  import { mean } from "../lib/descriptiveStatistics";

  let inputTuplesString: string =
    new URLSearchParams(window.location.search).get("tupleInput") ??
    //"(Export, 14) ; (Verknüpfung, 12) ; (Export, 12) ; (Verknüpfung, 13) ; (Export, 17)  ; (Export, 11) ; (Verknüpfung, 14) ; (Export, 10) ; (Export, 10) ; (Abfrage, 18) ; (Verknüpfung, 16) ; (Abfrage, 15)";
    "(1,3);(4,2);(4,5);(8,9);(3,3);(3,5)";
  function parseInputTupleString(input: string): Tuple<TupleContentType>[] {
    return input
      .split(";")
      .map((rawTuple) =>
        rawTuple
          .trim()
          .replace(")", "")
          .replace("(", "")
          .split(",")
          .map((tuplePart) => tuplePart.trim())
      )
      .filter((parts) => parts.length === 2) as Tuple<TupleContentType>[];
  }
  type TupleContentType = string;
  $: inputTuples = parseInputTupleString(inputTuplesString);
  $: N = inputTuples.length;
  $: uniqueTupleParts = getUniqueTupleParts(inputTuples);
  $: J = uniqueTupleParts[0].length;
  $: K = uniqueTupleParts[1].length;
  $: absoluteFrequencyMatrix = getAbsoluteFrequencyMatrix(
    uniqueTupleParts[0],
    uniqueTupleParts[1],
    inputTuples
  );
  $: relativeFrequencyMatrix = getRelativeFrequencyMatrix(
    uniqueTupleParts[0],
    uniqueTupleParts[1],
    inputTuples,
    absoluteFrequencyMatrix
  );
  $: contingencyTable = calculateContingencyTable(absoluteFrequencyMatrix);
  $: chiSquared = calculateChiSquared(
    absoluteFrequencyMatrix,
    contingencyTable
  );
  $: correctedContingencyCoefficient = calculateCorrectedContingencyCoefficient(
    chiSquared,
    J,
    K,
    N
  );
  $: numberTuplesAttempt = inputTuples.map(
    ([x, y]) => [Number(x), Number(y)] as Tuple<number>
  );
  $: numberTuples =
    numberTuplesAttempt.length === inputTuples.length &&
    numberTuplesAttempt.every(([x, y]) => !isNaN(x) && !isNaN(y))
      ? numberTuplesAttempt
      : [];
  $: covariance = calculateCovariance(numberTuples);
  $: [xVals, yVals] = splitTuples(numberTuples);

  let isTableModalVisible: boolean = false;
  function applyTuples(tuples: Tuple<TupleContentType>[]): void {
    inputTuplesString = tuples
      .map(([xk, yk]: Tuple<TupleContentType>) => "(" + xk + "," + yk + ")")
      .join(" ; ");
  }

  function generateRandomNumberTuples(): void {
    let tupleCount = 150;
    const minValue = 1;
    const maxValue = 15;
    const randomTuples = new Array<Tuple<number>>();
    while (tupleCount > 0) {
      randomTuples.push([
        randomNumber(minValue, maxValue),
        randomNumber(minValue, maxValue / 2),
      ]);
      tupleCount--;
    }

    inputTuplesString = randomTuples.map(([x, y]) => `(${x},${y})`).join(";");
  }

  function randomNumber(low: number, high: number): number {
    return Math.round(Math.random() * (high - low)) + low;
  }
</script>

<div class="box">
  <form class="form">
    <div class="field is-grouped ">
      <label class="label" for="x-values-input"
        >Urliste der Tupel (N={inputTuples.length})</label
      >
    </div>
    <div class="field has-addons">
      <div class="control is-expanded">
        <input
          id="x-values-input"
          class="input"
          type="text"
          bind:value={inputTuplesString}
          placeholder="1.6, 12.3, 15.3, 33.4"
        />
      </div>
      <div class="control">
        <button
          class="button is-primary"
          on:click|preventDefault={() => {
            isTableModalVisible = true;
          }}
        >
          Kontingenz
        </button>
      </div>
      <div class="control">
        <button
          class="button is-danger"
          on:click|preventDefault={generateRandomNumberTuples}
        >
          RNG
        </button>
      </div>
      <TableInputModal
        bind:isVisible={isTableModalVisible}
        on:applyTuples={(e) => applyTuples(e.detail)}
        xLabels={uniqueTupleParts[0]}
        yLabels={uniqueTupleParts[1]}
        {absoluteFrequencyMatrix}
      />
    </div>
    <div class="field">
      <label class="label" for="x-values-distinct"
        >Distinkte x-Werte (J={uniqueTupleParts[0].length})</label
      >
      <div class="control">
        <input
          disabled
          id="x-values-sorted"
          class="input"
          type="text"
          value={uniqueTupleParts[0].join("  ,  ")}
        />
      </div>
    </div>
    <div class="field">
      <label class="label" for="y-values-distinct"
        >Distinkte y-Werte (K={uniqueTupleParts[1].length})</label
      >
      <div class="control">
        <input
          disabled
          id="x-values-sorted"
          class="input"
          type="text"
          value={uniqueTupleParts[1].join("  ,  ")}
        />
      </div>
    </div>
    <div class="field">
      <p class="label">Weitere Kennzahlen</p>
      <p>
        <math xmlns="http://www.w3.org/1998/Math/MathML">
          <mtable columnalign="left">
            <mtr>
              <mover>
                <mi>x</mi>
                <mo accent="true">_</mo>
              </mover>
              <mo>=</mo>
              <mn>{roundToDigits(mean(xVals), 3)}</mn>
            </mtr>
          </mtable>
        </math>,
        <math xmlns="http://www.w3.org/1998/Math/MathML">
          <mtable columnalign="left">
            <mtr>
              <mover>
                <mi>y</mi>
                <mo accent="true">_</mo>
              </mover>
              <mo>=</mo>
              <mn>{roundToDigits(mean(yVals), 3)}</mn>
            </mtr>
          </mtable>
        </math>
      </p>
    </div>
  </form>
  <div class="mt-2">
    <hr />
  </div>
  <!--

    <div class="columns mt-2 is-desktop">
    <div class="column is-half">
      <Matrix
        topLeftLabel="Abs. Häufigk."
        xLabels={uniqueTupleParts[0]}
        yLabels={uniqueTupleParts[1]}
        matrix={absoluteFrequencyMatrix}
      />
    </div>
    <div class="column is-half">
      <Matrix
        topLeftLabel="Rel. Häufigk."
        xLabels={uniqueTupleParts[0]}
        yLabels={uniqueTupleParts[1]}
        matrix={relativeFrequencyMatrix}
      />
    </div>
  </div>

-->
  <div class="columns">
    <div class="column">
      <article class="message is-success">
        <div class="message-body">
          <p>
            <strong>Kontingenztafeln</strong> für absolute Häufigkeiten sind
            anschauliche Verteilungsmaße. Sie geben an, wie oft z.B. der x-Wert
            <i>"{uniqueTupleParts[0][0]}"</i>
            in allen Tupeln vorkommt. In diesem Fall würde man also alle Werte aus
            der ersten Zeile der Tafel der absoluten Häufigkeiten aufsummieren und
            rechts daneben schreiben.
            <br />
            Für die y-Werte ist das Verfahren analog anwendbar, nur summiert man
            da die Spalten auf.
          </p>
          <p>
            <strong>Kontingenztafeln</strong> für relative Häufigkeiten: Gleiches
            Spiel, nur mit den relativen Häufigkeiten.
          </p>
        </div>
      </article>
    </div>
  </div>
  <div class="columns">
    <div class="column is-half">
      <Matrix
        topLeftLabel="Kontingenz (abs.)"
        xLabels={[...uniqueTupleParts[0], "∑"]}
        yLabels={[...uniqueTupleParts[1], "∑"]}
        matrix={contingencyTable}
      />
    </div>
    <div class="column is-half">
      <Matrix
        topLeftLabel="Kontingenz (rel.)"
        xLabels={[...uniqueTupleParts[0], "∑"]}
        yLabels={[...uniqueTupleParts[1], "∑"]}
        matrix={calculateContingencyTable(relativeFrequencyMatrix)}
      />
    </div>
  </div>
  <hr />
  <div class="columns">
    <div class="column is-half">
      <Matrix
        topLeftLabel="Bed. W'keit"
        xLabels={[...uniqueTupleParts[0]]}
        yLabels={[...uniqueTupleParts[1], "∑"]}
        matrix={calculateConditionalXFrequencies(absoluteFrequencyMatrix)}
      />
    </div>
    <div class="column is-half">
      <Matrix
        topLeftLabel="Bed. W'keit"
        xLabels={[...uniqueTupleParts[0], "∑"]}
        yLabels={[...uniqueTupleParts[1]]}
        matrix={calculateConditionalYFrequencies(absoluteFrequencyMatrix)}
      />
    </div>
  </div>
  <hr />
  <div class="columns">
    <div class="column is-half">
      <Matrix
        topLeftLabel="v_jk"
        xLabels={[...uniqueTupleParts[0]]}
        yLabels={[...uniqueTupleParts[1]]}
        matrix={calculateVjk(absoluteFrequencyMatrix, contingencyTable)}
      />
      <ChiSquared />
      <math xmlns="http://www.w3.org/1998/Math/MathML" display="inline">
        <msup>
          <mi>χ</mi>
          <mn>2</mn>
        </msup>
      </math>: {chiSquared}
    </div>
    <div class="column is-half">
      <article class="message is-success">
        <div class="message-body">
          <p>
            Der <b> Kontingenzkoeffizient </b> (auch:
            <math xmlns="http://www.w3.org/1998/Math/MathML" display="inline">
              <msup>
                <mi>χ</mi>
                <mn>2</mn>
              </msup>
            </math>) ist ein Maß für den Zusammenhang zwischen den x- und den
            y-Werten. Ein kleiner Wert deutet auf einen geringen, ein hoher Wert
            auf einen hohen Zusammenhang hin.
          </p>
          <p>
            Auf dem Weg zu seiner Berechnung wird
            <math xmlns="http://www.w3.org/1998/Math/MathML" display="inline">
              <msub>
                <mi>v</mi>
                <mrow>
                  <mi>j</mi><mi>k</mi>
                </mrow>
              </msub>
            </math>
            benötigt.
          </p>
        </div>
      </article>
    </div>
  </div>
  <hr />
  <div class="columns">
    <div class="column is-half">
      <C {N} {J} {K} {chiSquared} />
    </div>
    <div class="column is-half">
      <article class="message is-success">
        <div class="message-body">
          <p>
            Der <strong>korrigierte Kontingenzkoeffizient nach Pearson </strong>
            (auch: C) ist die normierte Variante von
            <math xmlns="http://www.w3.org/1998/Math/MathML" display="inline">
              <msup>
                <mi>χ</mi>
                <mn>2</mn>
              </msup>
            </math>
            und grenzt diesen auf den Bereich von 0 bis 1 ein.
          </p>
          <p>
            <math xmlns="http://www.w3.org/1998/Math/MathML" display="inline">
              <mrow>
                <mi>C</mi>
                <mo>=</mo>
                <mi> {roundToDigits(correctedContingencyCoefficient, 5)}</mi>
              </mrow>
            </math>
            ist
            {#if correctedContingencyCoefficient <= 0.1}
              sehr, sehr niedrig und deutet damit auf kaum einen
            {:else if correctedContingencyCoefficient <= 0.3}
              sehr niedrig und deutet damit auf einen sehr geringen
            {:else if correctedContingencyCoefficient <= 0.5}
              niedrig und deutet damit auf einen geringen
            {:else if correctedContingencyCoefficient <= 0.85}
              relativ hoch und deutet damit auf einen
            {:else if correctedContingencyCoefficient > 0.85}
              sehr hoch und deutet damit auf einen starken
            {/if}
            Zusammenhang zwischen den x- und den y-Werten hin.
          </p>
        </div>
      </article>
    </div>
  </div>
  <hr />
  <div class="columns">
    <div class="column is-half">
      <Covariance tuples={numberTuples} />
    </div>
    <div class="column is-half">
      {#if numberTuples.length === 0}
        <article class="message is-warning">
          <div class="message-body">
            Die <b>Kovarianz</b> kann nur berechnet werden, wenn alle x- und y-Werte
            quantitiv, also Zahlen, sind.
          </div>
        </article>
      {:else}
        <article class="message is-success">
          <div class="message-body content">
            <b>Kovarianz:</b> Bivariates Zusammenhangsmaß
            <ul>
              <li>
                <p>
                  <math xmlns="http://www.w3.org/1998/Math/MathML">
                    <mtable columnalign="left">
                      <mtr>
                        <msub>
                          <mi>s</mi>
                          <mrow>
                            <mi>x</mi>
                            <mi>y</mi>
                          </mrow>
                        </msub>
                        <mo>&gt;</mo>
                        <mn>0</mn>
                      </mtr>
                    </mtable>
                  </math>
                  , wenn mit hohen x-Werten hohe y-Werte einhergehen (positive Korrelation)
                </p>
              </li>
              <li>
                <p>
                  <math xmlns="http://www.w3.org/1998/Math/MathML">
                    <mtable columnalign="left">
                      <mtr>
                        <msub>
                          <mi>s</mi>
                          <mrow>
                            <mi>x</mi>
                            <mi>y</mi>
                          </mrow>
                        </msub>
                        <mo>=</mo>
                        <mn>0</mn>
                      </mtr>
                    </mtable>
                  </math>
                  , wenn mit hohen x-Werten gleichermaßen hohe wie tiefe y-Werte
                  einhergehen (Unkorrelliertheit)
                </p>
              </li>
              <li>
                <p>
                  <math xmlns="http://www.w3.org/1998/Math/MathML">
                    <mtable columnalign="left">
                      <mtr>
                        <msub>
                          <mi>s</mi>
                          <mrow>
                            <mi>x</mi>
                            <mi>y</mi>
                          </mrow>
                        </msub>
                        <mo>&lt;</mo>
                        <mn>0</mn>
                      </mtr>
                    </mtable>
                  </math>
                  , wenn mit hohen x-Werten niedriege y-Werte einhergehen (negative
                  Korrelation)
                </p>
              </li>
            </ul>
            <p />
          </div>
        </article>
      {/if}
    </div>
  </div>
  <hr />
  <div class="columns">
    <div class="column">
      <details>
        <summary>
          <b>Korrelationskoeffizient nach Bravais-Pearson</b>
        </summary>
        <br />
        <BravisPersonCorrelation values={numberTuples} />
      </details>

      <details>
        <summary>
          <b>Rangkorrelation nach Spearman</b>
        </summary>
        <br />
        <SpearmanRankCorrelation values={numberTuples} />
      </details>

      <details id="bivariat-linear-regression">
        <summary>
          <b>Lineare Regression</b>
        </summary>
        <br />
        <LinearRegression values={numberTuples} />
      </details>
    </div>
  </div>
</div>
