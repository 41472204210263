<math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
  <semantics>
    <mrow>
      <msub><mi>Q</mi><mi>p</mi></msub><mo>=</mo>
      <mrow>
        <mo>{"{"}</mo>
        <mtable rowspacing="0.5ex" columnalign="left left" displaystyle="false">
          <mtr>
            <mtd>
              <msub>
                <mi>x</mi><mrow
                  ><mo stretchy="false">(</mo><mi>i</mi><mo stretchy="false"
                    >)</mo
                  ></mrow
                >
              </msub>
            </mtd>
            <mtd>
              <mo>,</mo><mspace width="0.16666666666666666em" /><mi>i</mi><mo
                >:=</mo
              ><mo stretchy="false">⌈</mo><mi>n</mi><mi>p</mi><mo
                stretchy="false">⌉</mo
              ><mo>,</mo><mi>n</mi><mi>p</mi>
              <mtext>&nbsp;nicht ganzzahlig</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>x</mi><mrow
                      ><mo stretchy="false">(</mo><mi>i</mi><mo stretchy="false"
                        >)</mo
                      ></mrow
                    >
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>x</mi><mrow
                      ><mo stretchy="false">(</mo><mi>i</mi><mo>+</mo><mn>1</mn
                      ><mo stretchy="false">)</mo></mrow
                    >
                  </msub>
                </mrow>
                <mn>2</mn>
              </mfrac>
            </mtd>
            <mtd>
              <mo>,</mo><mspace width="0.16666666666666666em" /><mi>i</mi><mo
                >:=</mo
              ><mi>n</mi><mi>p</mi><mo>,</mo><mspace
                width="0.16666666666666666em"
              /><mi>n</mi><mi>p</mi><mtext>&nbsp;ganzzahlig</mtext>
            </mtd>
          </mtr>
          <mtr><mtd /></mtr>
        </mtable>
        <undefined />
      </mrow>
    </mrow>
  </semantics>
</math>
