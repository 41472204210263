<!-- Arithmetisches Mittel -->
<script lang="ts">
  import { simpsonsDNormed } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  // TODO: Implement some display of values
  export let values: number[] | null;
</script>

<div class="formula table-container">
  <math
    display="block"
    xmlns="http://www.w3.org/1998/Math/MathML"
    maxwidth="300px"
    linebreakmultchar="×"
    linebreakstyle="before"
  >
    <semantics>
      <mrow>
        <msub>
          <mi>D</mi>
          <mi>Z</mi>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mi>J</mi>
          <mrow>
            <mi>J</mi>
            <mo>−</mo>
            <mn>1</mn>
          </mrow>
        </mfrac>
        <mo>⋅</mo>
        <mi>D</mi>
        <mo>=</mo>
        <mn>{roundToDigits(simpsonsDNormed(values), 3)}</mn>
      </mrow>
    </semantics>
  </math>
</div>
