<!-- Arithmetisches Mittel -->
<script lang="ts">
  import {
    letiDispersion,
    letiDispersionNormed,
    distinctify,
  } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";

  // TODO: Implement some display of values
  export let values: number[] | null;
</script>

<div class="formula table-container">
  <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
    <semantics>
      <mrow>
        <msub>
          <mi>D</mi>
          <mi>LZ</mi>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mn>4</mn>
          <mrow>
            <mi>J</mi>
            <mo>−</mo>
            <mn>1</mn>
          </mrow>
        </mfrac>
        <msub>
          <mi>D</mi>
          <mi>L</mi>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mn>4</mn>
          <mn> {distinctify(values).length - 1} </mn>
        </mfrac>
        <mo>⋅</mo>
        <mn> {roundToDigits(letiDispersion(values), 3)} </mn>
        <mo>=</mo>
        <mn> {roundToDigits(letiDispersionNormed(values), 3)} </mn>
      </mrow>
    </semantics>
  </math>
</div>
