<script lang="ts">
  import { median } from "../../lib/descriptiveStatistics";
  import { roundToDigits } from "../../lib/displayUtils";
  $: medianCalc = median(values);
  $: medianRes = roundToDigits(medianCalc, 3);

  export let values: number[] = [];
  $: values2 = values.map((v) => v - medianCalc);
  $: values3 = values2.map((v) => Math.abs(v));
  $: values4 = [...values3].sort((a, b) => a - b);
</script>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
    <mtable columnalign="left">
      <mtr>
        <mtd>
          <msub>
            <mtext>mad </mtext>
            <mi>x </mi>
          </msub>
        </mtd>
        <mtd>
          <mo>= </mo>
          <mtext>med </mtext>
          <mrow>
            <mo stretchy="true" form="prefix">( </mo>
            <mrow>
              <mo>| </mo>
              <msub>
                <mi>x </mi>
                <mi>i </mi>
              </msub>
              <mo>- </mo>
              <msub>
                <mtext>med </mtext>
                <mi>x </mi>
              </msub>
              <mo>| </mo>
            </mrow>
            <mo stretchy="true" form="postfix">) </mo>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mtext>med </mtext>
          <mrow>
            <mo stretchy="true" form="prefix">( </mo>
            <mrow>
              {#each values as value, i}
                <mo>| </mo>
                <mi>{roundToDigits(value, 3)}</mi>
                <mo>- </mo>
                <mi>{medianRes}</mi>
                <mo>| </mo>
                {#if i !== values.length - 1}
                  <mo>, </mo>
                {/if}
              {/each}
            </mrow>
            <mo stretchy="true" form="postfix">) </mo>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mtext>med </mtext>
          <mrow>
            <mo stretchy="true" form="prefix">( </mo>
            <mrow>
              {#each values2 as value, i}
                <mo>| </mo>
                <mi>{roundToDigits(value, 3)}</mi>
                <mo>| </mo>
                {#if i !== values.length - 1}
                  <mo>, </mo>
                {/if}
              {/each}
            </mrow>
            <mo stretchy="true" form="postfix">) </mo>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mtext>med </mtext>
          <mrow>
            <mo stretchy="true" form="prefix">( </mo>
            <mrow>
              {#each values3 as value, i}
                <mi>{roundToDigits(value, 3)}</mi>
                {#if i !== values.length - 1}
                  <mo>, </mo>
                  <mspace width="0.2em" />
                {/if}
              {/each}
            </mrow>
            <mo stretchy="true" form="postfix">) </mo>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mtext>med </mtext>
          <mrow>
            <mo stretchy="true" form="prefix">( </mo>
            <mrow>
              {#each values4 as value, i}
                {#if Math.floor((values.length - 1) / 2) === i || Math.ceil((values.length - 1) / 2) === i}
                  <mi mathvariant="bold">
                    {roundToDigits(value, 3)}
                  </mi>
                {:else}
                  <mi>
                    {roundToDigits(value, 3)}
                  </mi>
                {/if}

                {#if i !== values.length - 1}
                  <mo>, </mo>
                  <mspace width="0.2em" />
                {/if}
              {/each}
            </mrow>
            <mo stretchy="true" form="postfix">) </mo>
            <mspace width="1em" />
            <mtext> // Werte sortiert</mtext>
          </mrow>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
        <mtd>
          <mo>= </mo>
          <mi>{roundToDigits(median(values4), 3)} </mi>
        </mtd>
      </mtr>
      <mtr>
        <mtd />
      </mtr>
    </mtable>
  </math>
</div>
