<script lang="ts">
  import type { Tuple } from "../lib/bivariateStatistics";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher<{ applyTuples: Tuple<string>[] }>();

  export let isVisible: boolean = false;
  export let rows: string[][] = [
    ["", "", ""],
    ["", "", ""],
  ];
  export let absoluteFrequencyMatrix: unknown[][] = [];
  export let xLabels: string[] = [];
  export let yLabels: string[] = [];

  function importTupleListValues(): void {
    if (isVisible && absoluteFrequencyMatrix.length !== 0) {
      rows = [
        ["", ...yLabels],
        ...absoluteFrequencyMatrix.map((row, i) => [
          xLabels[i],
          ...row.map((cell) => "" + cell),
        ]),
      ];
    }
  }

  function emitResult(): void {
    dispatch("applyTuples", generateTuplesFromTable());
    isVisible = false;
    clearInput();
  }

  function generateTuplesFromTable(): Tuple<string>[] {
    const tuples: Tuple<string>[] = [];
    const rowLength = rows.length;
    const colLength = rows[0]?.length ?? 0;
    for (let rowIndex = 1; rowIndex < rowLength; rowIndex++) {
      for (let columnIndex = 1; columnIndex < colLength; columnIndex++) {
        const cellContent = "" + rows[rowIndex][columnIndex];
        let cellNumber = isNaN(Number(cellContent)) ? 0 : Number(cellContent);
        if (cellNumber > 0) {
          const xKey = rows[rowIndex][0];
          const yKey = rows[0][columnIndex];
          while (cellNumber > 0) {
            tuples.push([xKey, yKey]);
            cellNumber--;
          }
        }
      }
    }
    return tuples;
  }

  function cancel(): void {
    isVisible = false;
    clearInput();
  }

  function clearInput(): void {
    rows = [
      ["", "", ""],
      ["", "", ""],
    ];
  }

  function addRow(): void {
    rows = [...rows, rows[0].map((_) => "")];
  }
  function addColumn(): void {
    rows = rows.map((column) => [...column, ""]);
  }
</script>

<div class="modal" class:is-active={isVisible}>
  <div class="modal-background" />
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Tupel als Tabelle der absoluten Häufigkeiten eingeben
      </p>
      <button
        class="delete"
        aria-label="close"
        on:click|preventDefault={cancel}
      />
    </header>
    <section class="modal-card-body">
      <div class="table-container">
        <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
          <tbody>
            {#each rows as row, rowI}
              <tr>
                {#each row as cell, columnI}
                  {#if rowI !== 0 || columnI !== 0}
                    <td>
                      <input
                        type="text"
                        style="width: 100%"
                        bind:value={cell}
                      />
                    </td>
                  {:else}
                    <td>-</td>
                  {/if}
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
      <br />
      <button class="button is-cuccess" on:click|preventDefault={addRow}>
        + Zeile
      </button>
      <button class="button is-cuccess" on:click|preventDefault={addColumn}>
        + Spalte
      </button>
      <button
        class="button is-success"
        on:click|preventDefault={importTupleListValues}
      >
        Aus Tupelliste importieren
      </button>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" on:click|preventDefault={emitResult}
        >Übernehmen</button
      >
      <button class="button" on:click|preventDefault={clearInput}
        >Tabelle löschen</button
      >
      <button class="button" on:click|preventDefault={cancel}>Cancel</button>
    </footer>
  </div>
</div>
