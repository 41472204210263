<script lang="ts">
  import { afterUpdate } from "svelte";
  import { calculateCovariance, splitTuples } from "../lib/bivariateStatistics";
  import type { Tuple } from "../lib/bivariateStatistics";
  import { roundToDigits } from "../lib/displayUtils";
  import { calculateVariance, mean } from "../lib/descriptiveStatistics";
  import Variance from "./formulas/Variance.svelte";
  import Mean from "./formulas/Mean.svelte";

  export let values: Tuple<number>[];
  $: [xValues, yValues] = splitTuples(values);
  $: xMin = Math.min(...xValues);
  $: xMax = Math.max(...xValues);
  $: xVariance = calculateVariance(xValues);
  $: xMean = mean(xValues);
  $: yMean = mean(yValues);
  $: coVariance = calculateCovariance(values);

  function linearRegressionFunction(x: number): number {
    return (coVariance / xVariance) * (x - xMean) + yMean;
  }
  import type {
    Config as PlotlyConfig,
    Data,
    PlotlyWindow,
  } from "../lib/plotlyTypes";
  let plotDivElement: HTMLElement;
  const config: Partial<PlotlyConfig> = {
    responsive: true,
    modeBarButtonsToRemove: [
      "zoom2d",
      "zoomIn2d",
      "zoomOut2d",
      "pan2d",
      "autoScale2d",
      "select2d",
      "lasso2d",
      "zoomOut2d",
      "toggleSpikelines",
      "hoverCompareCartesian",
      "hoverClosestCartesian",
    ],
  };
  $: pointsTrace = {
    x: xValues,
    y: yValues,
    mode: "markers",
    type: "scatter",
    name: "y-Werte",
  };
  $: lineTrace = {
    x: [xMin - 1, ...xValues, xMax + 1],
    y: [
      linearRegressionFunction(xMin - 1),
      ...xValues.map((x) => linearRegressionFunction(x)),
      linearRegressionFunction(xMax + 1),
    ],
    mode: "lines",
    type: "scatter",
    name: "Regressionslinie",
    line: {
      dash: "dot",
      width: 2,
    },
  };

  // Plotly needs the plotDivElement to attach to it, so only run this _after_ updates
  afterUpdate(() => {
    ((window as unknown) as PlotlyWindow).Plotly.newPlot(
      plotDivElement,
      [pointsTrace as Data, lineTrace as Data],
      {
        yaxis: { fixedrange: true, title: "y" },
        xaxis: { fixedrange: true, title: "x" },
      },
      config
    );
  });

  let xInputToCalculateYWith: number = 0;
  $: calculatedYResult = linearRegressionFunction(xInputToCalculateYWith);
</script>

<div class="formula table-container">
  <Variance values={xValues} label="x" />
</div>

<div class="formula table-container">
  <Mean values={xValues} label="x" />
  <Mean values={yValues} label="y" />
</div>

<div class="formula table-container">
  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
    <mtable columnalign="left">
      <mtr>
        <mtd>
          <mi>d</mi>
        </mtd>
        <mtd>
          <mo>=</mo>
          <mfrac>
            <msub>
              <mi>s</mi>
              <mrow>
                <mi>x</mi>
                <mi>y</mi>
              </mrow>
            </msub>
            <msubsup>
              <mo> s</mo>
              <mn> x </mn>
              <mn> 2 </mn>
            </msubsup>
          </mfrac>
          <mo>=</mo>
          <mfrac>
            <mi>{roundToDigits(coVariance, 3)}</mi>
            <mi>{roundToDigits(xVariance, 3)}</mi>
          </mfrac>
          <mo>=</mo>
          <mi>{roundToDigits(coVariance / xVariance, 3)}</mi>
        </mtd>
        <mtd>
          <mspace width="1em" />
          <mtext> // Steigung pro x</mtext>
        </mtd>
      </mtr>
      <mtr>
        <mtd>
          <mi>c</mi>
        </mtd>
        <mtd>
          <mo>=</mo>
          <mover>
            <mi>y</mi>
            <mo accent="true">_</mo>
          </mover>
          <mo>-</mo>
          <mi>d</mi>
          <mo>·</mo>
          <mover>
            <mi>x</mi>
            <mo accent="true">_</mo>
          </mover>
          <mo>=</mo>
          <mi>{roundToDigits(yMean, 3)}</mi>
          <mo>-</mo>
          <mi>{roundToDigits(coVariance / xVariance, 3)}</mi>
          <mo>·</mo>
          <mi>{roundToDigits(xMean, 3)}</mi>
          <mo>=</mo>
          <mi>{roundToDigits(yMean - (coVariance / xVariance) * xMean, 3)}</mi>
          <mspace width="1em" />
        </mtd>
        <mtd>
          <mspace width="1em" />
          <mtext> // y-Achsenabschnitt</mtext>
        </mtd>
      </mtr>
      <mtr>
        <mtd>
          <msub>
            <mi>y</mi>
            <mi>i</mi>
          </msub>
        </mtd>
        <mtd>
          <mo>=</mo>
          <mi>c</mi>
          <mo>+</mo>
          <mi>d</mi>
          <mo>·</mo>
          <msub>
            <mi>x</mi>
            <mi>i</mi>
          </msub>
          <mo>=</mo>
          <mi>{roundToDigits(yMean - (coVariance / xVariance) * xMean, 3)}</mi>
          <mo>+</mo>
          <mi>{roundToDigits(coVariance / xVariance, 3)}</mi>
          <mo>·</mo>
          <msub>
            <mi>x</mi>
            <mi>i</mi>
          </msub>
        </mtd>
        <mtd>
          <mspace width="1em" />
          <mtext> // Funktion der Regressionslinie</mtext>
        </mtd>
      </mtr>
    </mtable>
  </math>
</div>
<br />

Ausrechnen für bestimmtes x:
<input type="number" bind:value={xInputToCalculateYWith} />
->

<math xmlns="http://www.w3.org/1998/Math/MathML" display="inline">
  <msub>
    <mi>y</mi>
    <mi>{xInputToCalculateYWith}</mi>
  </msub>
  <mo>=</mo>
  <mi>{roundToDigits(calculatedYResult, 3)}</mi>
</math>

<div bind:this={plotDivElement} />
